import { useState, useCallback, useEffect } from "preact/hooks";

export const BASE_AUTH_URL = "https://report.admin.myintelli.net";
const URL_REFRESH_TOKEN = BASE_AUTH_URL + "jwt/refresh/";
const STORAGE_KEY = "TOKEN_AUTH";
const { fetch: originalFetch } = window;

const createTokenProvider = () => {
  let observers = [];

  const getTokenInternal = () => {
    const data = localStorage.getItem(STORAGE_KEY);
    const token = (data && JSON.parse(data)) || null;
    return token;
  };

  const getUser = () => {
    return !!_token ? _token.user : null;
  };

  let _token = getTokenInternal();
  const getExpirationDate = (jwtToken) => {
    if (!jwtToken) {
      return null;
    }

    const jwt = JSON.parse(atob(jwtToken.split(".")[1]));

    return (jwt && jwt.exp && jwt.exp * 1000) || null;
  };

  const isExpired = (exp) => {
    if (!exp) {
      return false;
    }
    return Date.now() > exp;
  };

  const getToken = async () => {
    if (!_token) {
      return null;
    }

    if (isExpired(getExpirationDate(_token.token))) {
      setToken(null);
    }

    return _token && _token.token;
  };

  const isLoggedIn = () => {
    return !!getTokenInternal();
  };

  const subscribe = (observer) => {
    observers.push(observer);
  };

  const unsubscribe = (observer) => {
    observers = observers.filter((_observer) => _observer !== observer);
  };

  const notify = () => {
    const isLogged = isLoggedIn();
    observers.forEach((observer) => observer(isLogged));
  };

  const setToken = (token) => {
    if (token) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(token));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
    _token = getTokenInternal();
    notify();
  };

  return {
    getToken,
    isLoggedIn,
    setToken,
    subscribe,
    unsubscribe,
    getUser,
  };
};

const createAuthProvider = () => {
  const tokenProvider = createTokenProvider();

  const login = (newTokens) => {
    tokenProvider.setToken(newTokens);
  };

  const logout = () => {
    tokenProvider.setToken(null);
  };

  const http = async (...args) => {
    let [resource, config = {}] = args;
    console.info(resource)
    // request interceptor here
    const token = await tokenProvider.getToken();
    const new_config = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      
    };
    const response = await originalFetch(`${BASE_AUTH_URL}${resource}`, new_config);
    // response interceptor here
    if (!response.ok && response.status === 401) {
      logout();
      window.location = "/login";
      return Promise.reject(response);
    }
    return response;
  };

  const useAuth = () => {
    const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());
    const [user, setUser] = useState(tokenProvider.getUser());

    const listener = useCallback(
      (newIsLogged) => {
        setIsLogged(newIsLogged);
      },
      [setIsLogged]
    );

    useEffect(() => {
      tokenProvider.subscribe(listener);
      return () => {
        tokenProvider.unsubscribe(listener);
      };
    }, [listener]);

    return [isLogged, user];
  };

  return [useAuth, http, login, logout];
};

export const [useAuth, http, login, logout] = createAuthProvider();
