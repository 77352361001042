import { h, render, Component } from "preact";
import { useForm } from "react-hook-form";
import { route } from "preact-router";

import { BASE_AUTH_URL, login } from "../auth";

const LoginPage = () => {
  const { register, handleSubmit, reset, setValue, getValues } = useForm();

  const onSubmit = (data) => {
    fetch(`${BASE_AUTH_URL}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        return Promise.reject(res);
      })
      .then((res) => {
        login(res);
        route("/", true);
      })
      .catch((err) => {
        alert("Username or password incorrect");
      });
  };

  return (
    <section class="hero is-primary is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <form onSubmit={handleSubmit(onSubmit)} class="box">
                <div class="field">
                  <label for="" class="label">
                    Email
                  </label>
                  <div class="control has-icons-left">
                    <input
                      {...register("email")}
                      type="email"
                      placeholder=""
                      class="input"
                      required
                    />
                    <span class="icon is-small is-left">
                      <span class="material-icons">perm_identity</span>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label for="" class="label">
                    Password
                  </label>
                  <div class="control has-icons-left">
                    <input
                      type="password"
                      {...register("password")}
                      placeholder="*******"
                      class="input"
                      required
                    />
                    <span class="icon is-small is-left">
                      <span class="material-icons">lock</span>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <button class="button is-success">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
